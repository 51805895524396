@use "scss-reset/reset";
@import "./vars";

* {
  box-sizing: border-box;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0,
  "wdth" 100,
  "GRAD" 0,
  "XOPQ" 96,
  "XTRA" 468,
  "YOPQ" 79,
  "YTAS" 750,
  "YTDE" -203,
  "YTFI" 738,
  "YTLC" 514,
  "YTUC" 712;
  scroll-behavior: smooth
}

body {
  color: $main-color;
  background-color: $bg-color;
  font-family: "Roboto Flex", sans-serif;
  font-size: 14px;
  line-height: 1.28;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
p,
span {
  font-weight: 500;
  text-transform: uppercase;
}

a, button, input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  color: inherit;
  text-decoration: none;
}

input {
  background-color: transparent;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  //color: $main-color;
  background-color: $bg-color !important;
  background-image: none !important;
  -webkit-box-shadow: 0 0 0 50px $bg-color inset !important;
  -webkit-text-fill-color: $main-color !important;
  //caret-color: auto;
  outline: none !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.body__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

.accent {
  color: $accent-color;
}

.container {
  margin: 0 auto;
  padding: 24px 16px;
  max-width: 390px;
}

.section__title {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1;

  &.center {
    text-align: center;
  }

  @media screen and (min-width: 820px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 52px;
    font-size: 56px;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 84px;
    font-size: 80px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 106px;
  }
}

.vacancy__container {
  margin: 0 auto;

  max-width: 390px;
}

.careers__container {
  margin: 0 auto;
  padding: 0 24px;
  max-width: 390px;
}

.button {
  padding: 12px 24px;
  border-radius: 75px;
  display: block;
  color: $bg-color;
  background-color: $main-color;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  &:hover, &:focus, &:active {
    background-color: $accent-color;
  }

  &:disabled {
    background-color: rgba(66, 66, 53, 0.4);
    color: rgba(242, 242, 242, 0.4);
  }
}

.main__line {
  margin: 0 12px;
  width: calc(100vw - 24px);
  height: 1px;
  background-color: $divider-color;
}

.mobile-none {
  display: none;
}

.mobile-only {
  display: block;
}

.not-found__box {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  & > h1 {
    font-size: 72px;
  }
}

@media screen and (min-width: 820px) {
  body,
  a,
  button {
    font-size: 18px;
  }
  .button {
    font-size: 22px;
    padding: 12px 40px;
  }

  .container {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  .section {
    padding: 16px 0 84px;
  }
  .section__wrapper {
    max-width: 768px;
    margin: 0 auto;
  }
  .vacancy__container {
    max-width: 100%;
    padding: 0 84px;
  }
  .mobile-only {
    display: none;
  }

  .careers__container {
    padding: 0;
    max-width: 420px;
    margin: 16px auto 84px;
  }
  .mobile-none {
    display: block;
  }
  .mobile-none.careers__footer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 12px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .section__wrapper {
    max-width: 1024px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1440px) {
  body,
  a,
  button {
    font-size: 24px;
  }

  .button {
    font-size: 32px;
    padding: 20px 48px;
  }
  .section {
    padding: 32px 0 120px;
  }
  .section__wrapper {
    max-width: 1440px;
  }
  .vacancy__container {
    padding: 0 125px;
    margin-bottom: 120px;
  }
  .careers__container {
    max-width: 592px;
    margin: 32px auto 84px;
  }
  .mobile-none.careers__footer {
    gap: 48px;
    padding: 0 16px;
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 1920px) {
  body,
  a,
  button {
    font-size: 32px;
  }
  .button {
    font-size: 40px;
    padding: 32px 72px;
  }
  .section {
    padding: 32px 0 160px;
  }
  .section__wrapper {
    max-width: 1920px;
  }
  .vacancy__container {
    padding: 0 160px;
    margin-bottom: 160px;
  }
  .careers__container {
    max-width: 792px;
    margin: 32px auto 160px;
  }
  .mobile-none.careers__footer {
    gap: 64px;
    padding: 0 20px;
    margin-bottom: 64px;
  }
}
