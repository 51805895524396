@import "../../../styles/vars";
.hireUs__backdrop {
  overflow-y: scroll;
  position: fixed;
  z-index: 101;
  top: 0;
  right: -100%;
  width: 100vw;
  min-height: 100vh;
  background-color: transparent;
  transition: background-color 500ms ease-in-out, right 250ms ease-in-out;
  &--active {
    background-color: rgba(15, 15, 12, 0.9);
    right: 0;
  }
}
.hireUs__layout {
  position: relative;
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;
  background-color: $bg-color;
}
.hireUs__close {
  position: absolute;
  top: 24px;
  left: 24px;
}
.hireUs__title {
  font-size: 48px;
  text-align: center;
}
.HireUs__form-box {
  margin: 42px auto;
  max-width: 342px;
}
.HireUs__img--mob {
  margin-top: 48px;
  padding-left: 12px;
  padding-right: 12px;
}
.HireUs__img--tab {
  display: none;
}
@media screen and (min-width: 820px) {
  .hireUs__layout {
    position: absolute;
    right: 0;
    border-left: 2px solid $main-color;
    padding-top: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    width: 560px;
  }
  .hireUs__close {
    top: 48px;
    left: -80px;
  }
  .hireUs__title {
    color: $accent-color;
    font-size: 40px;
  }
  .HireUs__form-box {
    margin-top: 0;
    max-width: 480px;
    width: 100%;
  }
  .HireUs__img--mob {
    display: none;
  }
  .HireUs__img--tab {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1440px) {
  .hireUs__layout {
    padding-top: 24px;
  }
}
@media screen and (min-width: 1440px) {
  .hireUs__layout {
    padding-top: 64px;
    width: 746px;
  }
  .hireUs__title {
    font-size: 54px;
  }
  .HireUs__form-box {
    max-width: 656px;
  }
}